import React, { createContext, useState, useEffect, useContext } from "react";
import { HASURA_ENDPOINT_DEV } from "../config";
import {
  createClient,
  Provider,
  defaultExchanges,
  subscriptionExchange,
} from "urql";
import { SubscriptionClient } from "subscriptions-transport-ws";
import { app } from "./firebaseConfig";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
} from "firebase/auth";
export const AuthContext = createContext({});

const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({
    loading: true,
    token: undefined,
    userId: undefined,
  });
  const firebaseAuth = getAuth(app);

  let headers = {};

  const setAuthData = (data) => {
    console.log(data);
    if (!data) {
      localStorage.clear();
      setAuth({ loading: false, token: undefined, userId: undefined });
    } else {
      setAuth({
        ...auth,
        token: data?.token,
        userId: data?.userId,
        loading: false,
      });
      localStorage.setItem("userId", data?.userId);
      localStorage.setItem("token", data?.token);
    }
  };

  if (auth?.token) {
    headers = {
      Authorization: `Bearer ${auth?.token}`,
    };
  }

  const subscriptionClient = new SubscriptionClient(
    `wss://${HASURA_ENDPOINT_DEV}`,
    {
      reconnect: false,
      connectionParams: {
        headers,
      },
    }
  );

  const client = createClient({
    url: process.env.HASURA_ENDPOINT || `https://${HASURA_ENDPOINT_DEV}`,
    exchanges: [
      ...defaultExchanges,
      subscriptionExchange({
        forwardSubscription(operation) {
          return subscriptionClient.request(operation);
        },
      }),
    ],
    requestPolicy: "network-only",
    // fetch: fetch,
    fetchOptions: () => {
      if (auth?.token === null) {
        return true;
      }
      return {
        headers,
      };
    },
  });

  const firebaseSignup = (email, password) => {
    return createUserWithEmailAndPassword(firebaseAuth, email, password);
  };
  const firebaseSignIn = (email, password) => {
    return signInWithEmailAndPassword(firebaseAuth, email, password);
  };
  const firebaseSignOut = () => {
    return signOut(firebaseAuth);
  };

  // useEffect(() => {
  //   setAuth({
  //     loading: false,
  //     userId: localStorage.getItem("userId"),
  //   });
  // }, []);
  // useEffect(() => {
  //   const unsub = onAuthStateChanged(firebaseAuth, (user) => {
  //     setAuth({ ...auth, userId: user?.uid, loading: false });
  //   });
  //   return unsub;
  // }, []);
  //2. if object with key 'authData' exists in localStorage, we are putting its value in auth.data and we set loading to false.
  //This function will be executed every time component is mounted (every time the user refresh the page);

  useEffect(() => {
    let token = localStorage.getItem("token");
    let userId = localStorage.getItem("userId");
    console.log(token, userId, "<<<here");
    if (token && userId) {
      setAuth({
        loading: false,
        token: token,
        userId: userId,
      });
    } else {
      setAuth({
        loading: false,
        token: undefined,
        userId: undefined,
      });
    }
  }, []);
  // 1. when **auth.data** changes we are setting **auth.data** in localStorage with the key 'authData'.

  return (
    <AuthContext.Provider
      value={{
        auth,
        setAuthData,
      }}
    >
      <Provider value={client}>{children}</Provider>
    </AuthContext.Provider>
  );
};

export default AuthProvider;
