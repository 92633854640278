// export const baseAPIUrl = "http://localhost:4004";
export const baseAPIUrl = "https://api.gruve.link";
export const HASURA_ENDPOINT_DEV = "hasura.gruve.link/v1/graphql";

// Production
export const FIREBASE_API_KEY = "AIzaSyC8XcteCcvhPodNhUb3LF5rhIVTkVrLu5o";
export const FIREBASE_AUTH_DOMAIN = "gruve-nft.firebaseapp.com";
export const FIREBASE_PROJECT_ID = "gruve-nft";
export const FIREBASE_STORAGE_BUCKET = "gruve-nft.appspot.com";
export const FIREBASE_MESSAGING_SENDER_ID = "64444607729";
export const FIREBASE_APP_ID = "1:64444607729:web:1dc594aa16e462ed68cd50";
export const FIREBASE_MEASUREMENT_ID = "G-R9HSGK54S4";

// Personal
