import React, { useContext } from "react";
import { Route, Navigate, Outlet, Routes } from "react-router-dom";
import { AuthContext } from "./context/AuthContext";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { auth } = useContext(AuthContext);
  const { loading, token } = auth;

  if (loading) {
    return (
      <Routes>
        <Route
          {...rest}
          render={() => {
            return (
              <div className="relative">
                <div class="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 lds-dual-ring"></div>
              </div>
            );
          }}
        />
      </Routes>
    );
  }
  // if loading is set to true (when our function useEffect(() => {}, []) is not executed), we are rendering a loading component;
  console.log("private route");
  if (!token) {
    return <Navigate to="/login" replace />;
  }
  return <Component />;
};

export default PrivateRoute;
