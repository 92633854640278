import React from "react";

function ThemedSuspense() {
  return (
    <div className="relative">
      <div class="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 lds-dual-ring"></div>
    </div>
  );
}

export default ThemedSuspense;
